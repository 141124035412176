
const words = [
  '欢迎来到我的小站',
  '更多前端学习笔记即将发布，一些前端小实验小工具陆续上线。',
  '你好游客',
  '报价',
  '资历',
  '资历',
  '年薪',
  '等级',
  '最低工资',
  '成本',
]
export default words.reduce((locale, word)=>{
  if (typeof word === 'string') {
    locale[word] = word;
  }
  return locale
},{})