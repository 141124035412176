import { createRouter, createWebHashHistory } from 'vue-router'
const entrance = `@/index/views`

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/notes',
    // component: () => require(`@/index/views/home.vue`) // Y
    component: () => import(`@/index/views/home.vue`) // Y
    // component: () => require(`${entrance}/home.vue`) // N
    // component: () => import(`${entrance}/home.vue`)
    // component: (resolve) => require([`${entrance}/home`], resolve),
  },
  {
    path: '/notes',
    name: 'notes',
    meta: { title: '记录' },
    component: () => import(`@/index/views/notes.vue`)
  },
  {
    path: '/playground',
    name: 'playground',
    meta: { title: '实验' },
    component: () => import(`@/index/views/playground.vue`)
  },
  {
    path: '/quotation',
    name: 'quotation',
    meta: { title: '私单' },
    component: () => import(`@/index/views/quotation.vue`)
  },
  {
    path: '/resume',
    name: 'resume',
    meta: { title: '简历' },
    component: () => import(`@/index/views/resume.vue`)
  },
  {
    path: '/works',
    name: 'works',
    meta: { title: '作品' },
    component: () => import(`@/index/views/works.vue`)
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
