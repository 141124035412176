import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/css/reset.css'
import '@/assets/css/main.css'

import 'highlight.js/styles/github.css'

import i18n from '@/index/i18n'

import SvgIcon from "@/components/SvgIcon";
const requireAll = requireContext => requireContext.keys().map(requireContext);
const req = require.context("@/assets/icons/svg", false, /.svg$/);
requireAll(req);

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .component("svg-icon", SvgIcon)
  .mount('#app')
