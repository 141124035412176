<template>
<aside>
  <svg-icon icon-class="ZIM" style="font-size: 100px;"></svg-icon>
  <nav>
    <router-link to="/">记录</router-link>
    <router-link to="/playground">实验</router-link>
    <router-link to="/playground">工具</router-link>
  </nav>
  <a class="icp" href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备2022016124号</a>
</aside>
</template>

<script>
import { SETTINGS } from "@/SETTINGS";
// import Logo from '@/assets/icons/svg/ZIM.svg'; 
export default {
  name: 'NavBar',
  components: {
    // Logo
  },
  data() {
    return {
      SETTINGS
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
aside{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid #efefef;
  box-shadow:  3px 0 3px $g1;
  .svg-icon{
    margin: 60px;
  }
  nav {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 30px;
    a {
      color: #000;
      font-size: 16px;
      line-height: 2;
      letter-spacing: 3px;
      margin: 0 0 6px 0;
      &.router-link-exact-active {
      }
    }
  }
  .icp{
    font-size: 12px;
    color: #ccc;
    margin: 20px 0;
  }
}

</style>
