<template>
  <NavBar/>
  <main>
    <TopBar/>
    <router-view/>
  </main>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/index/components/NavBar.vue'
import TopBar from '@/index/components/TopBar.vue'

export default {
  name: 'IndexView',
  components: {
    NavBar,
    TopBar
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;

/* width: 100vw; */
/* height: 100vh; */
  width: 100%;
  height: 100%;
}
</style>
<style lang="scss" scoped>
main{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background-color: $g1;
}
</style>
