export default {
  '简历': 'resume',
  '作品': 'works',
  '简介': 'introduction',
  '履历': 'experience',
  '报价': 'quotation',
  '估价': 'estimate',
  '参考价': 'reference',
  '闲时': 'spare_time',
  '资历': 'tenure',
  '区别': 'distinction',
  '年薪': 'annual_salary',
  '月薪': 'monthly_salary',
  '最低工资': 'minimum wage',
  '成本': 'cost',
  '期望薪资': 'expected_salary',
  '等级': 'level',
  '资格': 'qualified',
  '服务': 'service',
  'LOGO设计': 'logo',
  'VI（视觉识别系统）设计': 'VI',
  '应用图标设计': 'icon',
  '网页设计（仅设计不编程）': 'web',
  '移动应用界面设计': 'app',
  '表情设计': 'expression',
  '用户体验咨询': 'experience',
  'banner广告图设计': 'banner',
}