<template>
<header>
  <span>你好，游客</span>
  <nav>
    <router-link to="/quotation">工作</router-link>
    <router-link to="/resume">简历</router-link>
    <router-link to="/works">作品</router-link>
    <router-link to="/playground">github</router-link>
  </nav>
  <select v-model="$i18n.locale">
    <option v-for="(lang, i) in ['ch', 'en']" :key="`Lang${i}`" :value="lang">
      {{ lang }}
    </option>
  </select>
</header>
</template>

<script>
import { SETTINGS } from "@/SETTINGS";
// import Logo from '@/assets/icons/svg/ZIM.svg'; 
export default {
  name: 'NavBar',
  components: {
    // Logo
  },
  data() {
    return {
      SETTINGS,
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #efefef;
  padding: 0 20px;
  background-color: $g2;
  box-shadow: 0 3px 3px $g2;
  >span{
    font-size: 14px;
  }
  nav {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    a {
      color: #000;
      font-size: 14px;
      line-height: 2;
      letter-spacing: 3px;
      margin: 0 0 0 20px;
      &.router-link-exact-active {
      }
    }
  }
}

</style>
